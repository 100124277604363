import { OrderRequestItemSchema, OrderRequestItemStatusEnum } from '../../../../../../types/schema';
import _ from 'lodash';

export const LeastItemStatues = [
  {
    label: 'Open',
    value: OrderRequestItemStatusEnum.Open,
    nextStatusFor: [],
  },
  {
    label: 'Back-ordered',
    value: OrderRequestItemStatusEnum.BackOrdered,
    nextStatusFor: [],
  },
  {
    label: 'Ordered',
    value: OrderRequestItemStatusEnum.Ordered,
    nextStatusFor: [
      OrderRequestItemStatusEnum.Open,
      OrderRequestItemStatusEnum.BackOrdered,
      OrderRequestItemStatusEnum.Ordered,
    ],
  },
  {
    label: 'Packed',
    value: OrderRequestItemStatusEnum.Packed,
    nextStatusFor: [],
  },
  {
    label: 'Out-for-delivery',
    value: OrderRequestItemStatusEnum.OutForDelivery,
    nextStatusFor: [],
  },
  {
    label: 'Delivered',
    value: OrderRequestItemStatusEnum.Delivered,
    nextStatusFor: [OrderRequestItemStatusEnum.Packed, OrderRequestItemStatusEnum.Delivered],
  },
  {
    label: 'Return packed',
    value: OrderRequestItemStatusEnum.ReturnPacked,
    nextStatusFor: [],
  },
  {
    label: 'Return out-for-delivery',
    value: OrderRequestItemStatusEnum.ReturnOutForDelivery,
    nextStatusFor: [],
  },
  {
    label: 'Returned',
    value: OrderRequestItemStatusEnum.Returned,
    nextStatusFor: [OrderRequestItemStatusEnum.ReturnPacked],
  },
  {
    label: 'Cancelled',
    value: OrderRequestItemStatusEnum.Cancelled,
    nextStatusFor: [
      OrderRequestItemStatusEnum.Open,
      OrderRequestItemStatusEnum.BackOrdered,
      OrderRequestItemStatusEnum.Ordered,
    ],
  },
  {
    label: 'Merged',
    value: OrderRequestItemStatusEnum.Merged,
    nextStatusFor: [
      OrderRequestItemStatusEnum.Open,
      OrderRequestItemStatusEnum.BackOrdered,
      OrderRequestItemStatusEnum.Ordered,
    ],
  },
  {
    label: 'Closed',
    value: OrderRequestItemStatusEnum.Closed,
    nextStatusFor: [
      OrderRequestItemStatusEnum.Open,
      OrderRequestItemStatusEnum.BackOrdered,
      OrderRequestItemStatusEnum.Ordered,
      OrderRequestItemStatusEnum.Cancelled,
      OrderRequestItemStatusEnum.Delivered,
    ],
  },
];

export function getLeastItemStatusLabel(status: OrderRequestItemStatusEnum) {
  return _.find(LeastItemStatues, (leastItemStatus) => leastItemStatus.value === status)?.label;
}

export function getNextValidStatus(orderRequestItem: OrderRequestItemSchema) {
  return _.filter(LeastItemStatues, (leastItemStatus) => {
    const isValidNextStatus = leastItemStatus.nextStatusFor?.includes(orderRequestItem.status);

    if (
      isValidNextStatus &&
      orderRequestItem.parentOrderRequestItemId &&
      leastItemStatus.value === OrderRequestItemStatusEnum.Delivered
    ) {
      return false;
    }
    return isValidNextStatus;
  });
}
